import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {ThresholdLevel} from '../../../../../../../src/app/globals';
import {BadgeColorType} from '../../enums/badge-color-type';
import {UserData} from '../../models/user-data';
import {UpdateUserModel} from '../../models/update-user-model';
import { IUniversalScreenerStatusModel } from '../../models/universal-screener-model';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'Setup User Data': emptyProps(),
    'Setup Student Data': emptyProps(),
    'Setup Teacher Data': emptyProps(),
    'Setup PLS Data': emptyProps(),
    'Setup User Data Success': props<{data: UserData}>(),
    'Setup User Data Failure': props<{error: HttpErrorResponse}>(),
  }
});

export const StudentLexileColorActions = createActionGroup({
  source: 'User',
  events: {
    'Load Student Lexile Color': emptyProps(),
    'Load Student Lexile Color Success': props<{
      thresholdLevel: ThresholdLevel;
      badgeType: BadgeColorType;
    }>(),
  }
});

export const PowerTextModeActions = createActionGroup({
  source: 'PowerTextMode',
  events: {
    'Load Power Text Mode': emptyProps(),
    'Load Power Text Mode Success': props<{isInPowerTextMode: boolean}>(),
    'Load Power Text Mode Failure': props<{error: HttpErrorResponse}>()
  }
});

export const UpdateUserActions = createActionGroup({
  source: 'Update User',
  events: {
    'Update User': props<{request: UpdateUserModel}>(),
    'Update User Success': props<{request: UpdateUserModel}>(),
    'Update User Failure': props<{error: HttpErrorResponse}>(),
  }
});

export const UpdatePasswordActions = createActionGroup({
  source: 'Update Password',
  events: {
    'Update Password': props<{currentPassword: string, newPassword: string}>(),
    'Update Password Success': emptyProps(),
    'Update Password Failure': props<{error: HttpErrorResponse}>(),
  }
});

export const UniversalScreenerActions = createActionGroup({
  source: 'Universal Screener',
  events: {
    'Load Universal Screener Status': emptyProps(),
    'Load Universal Screener Status Success': props<{data: IUniversalScreenerStatusModel}>(),
    'Load Universal Screener Status Failure': props<{error: HttpErrorResponse}>(),
    'Start Universal Screener': emptyProps()
  }
});
